var w;
var h;

function calcWindowWidthAndHeight() {
    w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

function cookieEmergency() {
    Cookies.set('emergency-close', 'close');
    $('.emergencySticky').css('display','none');
}

function cookieCastle() {
    Cookies.set('castle-close', 'close');
    $('.castlePink').css('display', 'none');
}

$(document).ready(function(){

    $('#register_form #reg_password, #register_form #reg_confpassword, #pass_recover #forget_password, #pass_recover #forget_confpassword, #password_form #change_password, #password_form #change_conf_password').keyup(function() {
        var input = $(this);
        var form = $(this).closest('form');
        input.parent().find('.pass_error').empty();

        var pass = $(this).val();
        var valid = false;
        var strength = 0;

        if (pass.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
            valid = true;
            strength += 1;
        } else {
            valid = false;
        }

        if (pass.match(/([a-zA-Z])/) && pass.match(/([0-9])/)) {
            valid = true;
            strength += 1;
        } else {
            valid = false;
        }

        if (pass.match(/([!,%,&,@,#,$,^,*,?,_,~,-])/)) {
            valid = true;
            strength += 1;
        } else {
            valid = false;
        }

        if (pass.length > 6) {
            valid = true;
            strength += 1;
        } else {
            valid = false;
        }

        if (valid == true && strength == 4) {
            form.find('input[type=submit]').attr("disabled", false);
            input.parent().find('.pass_error').empty();
        } else {
            form.find('input[type=submit]').attr("disabled", true);
            input.parent().find('.pass_error').append("<p>Veuillez renseigner un mot de passe contenant au moins 6 caractères avec une minuscule, une majuscule, un nombre et un caractère spécial </p>");
        }

    });

    // Check if confirm password is equal to password
    $('#register_form #reg_confpassword').keyup(function () {
        var input = $(this);
        var form = $(this).closest('form');

        input.parent().find('.confpass_error').empty();

        var pass = form.find('#reg_password').val();

        if (input.val() == pass) {
            form.find('input[type=submit]').attr("disabled", false);
            input.parent().find('.confpass_error').empty();
        } else {
            form.find('input[type=submit]').attr("disabled", true);
            input.parent().find('.confpass_error').append("<p>Veuillez renseigner des mots de passe identique</p>");
        }
    });

    $('#pass_recover #forget_confpassword').keyup(function () {
        var input = $(this);
        var form = $(this).closest('form');

        input.parent().find('.confpass_error').empty();

        var pass = form.find('#forget_password').val();

        if (input.val() == pass) {
            form.find('input[type=submit]').attr("disabled", false);
            input.parent().find('.confpass_error').empty();
        } else {
            form.find('input[type=submit]').attr("disabled", true);
            input.parent().find('.confpass_error').append("<p>Veuillez renseigner des mots de passe identique</p>");
        }
    });

    $('#password_form #change_conf_password').keyup(function () {
        var input = $(this);
        var form = $(this).closest('form');

        input.parent().find('.confpass_error').empty();

        var pass = form.find('#change_password').val();

        if (input.val() == pass) {
            form.find('input[type=submit]').attr("disabled", false);
            input.parent().find('.confpass_error').empty();
        } else {
            form.find('input[type=submit]').attr("disabled", true);
            input.parent().find('.confpass_error').append("<p>Veuillez renseigner des mots de passe identique</p>");
        }
    });


    $.validator.addMethod("validateRecaptcha", function (value, element) {
        if (grecaptcha.getResponse() == '') {
            return false;
        } else {
            return true;
        }
    }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
        if ($('#container_form form').length) {
            $('#contactRgpd').rules('add', {
                validateCgv: true
            });
            $('#f_recaptcha').rules('add', {
                validateRecaptcha: true
            });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });

    $.validator.addMethod("validateCgv", function (value, element) {
        return $('#contactRgpd').is(':checked');
    }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form, .form-user form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    $('.viewList').addClass('active');

    $('.viewList').click(function() {
        $('.viewMap').removeClass('active');
        $(this).addClass('active');
        $('.listingMap').hide();
        $('.listingList').show();
    });

    $('.viewMap').click(function() {
        $('.viewList').removeClass('active');
        $('#mapid').css('left', '0');
        $('#mapid').css('position', 'relative');
        $(this).addClass('active');
        $('.listingMap').show();
        $('.listingList').hide();
    });

    /* VIDEO BG */

    var timeoutId;

    // function videobgEnlarge() {

    //     var videoBgAspect = $(".videobg-aspect");
    //     var videoBgWidth = $(".videobg-width");
    //     var videoAspect = videoBgAspect.outerHeight() / videoBgAspect.outerWidth();
    //     var windowAspect = ($(window).height() / $(window).width());

    //     if (windowAspect > videoAspect) {
    //         videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
    //     } else {
    //         videoBgWidth.width(100 + "%")
    //     }

    // }

    // $(window).resize(function() {
    //   clearTimeout(timeoutId);
    //   timeoutId = setTimeout(videobgEnlarge, 100);
    // });

    // $(function() {
    //   videobgEnlarge();
    // });

    function videoHeight() {
        var height = $('.videobg-make-height').outerHeight(true);
        console.log(height);

        $('.videobg').css('height', height);
    }

    $(window).resize(function() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(videoHeight, 100);
    });
    $(function() {
      videoHeight();
    });


    $('#closeEmergency').click(function() {
        cookieEmergency();
    });

    if (Cookies.get('emergency-close') == 'close') {
        $('.emergencySticky').css('display','none');
    }

    $('.closeCastlePink').click(function() {
        $('.castlePink').css('display', 'none');
        cookieCastle();
    });

    if (Cookies.get('castle-close') == 'close') {
        $('.castlePink').css('display','none');
    }

    $('.owl-carousel-home').owlCarousel({
        loop: true,
        nav: false,
        items: 1,
        dots: false,
        autoplay:true,
        animateOut: 'fadeOut',
        autoplayTimeout: 5000
    });

    $('.owl-sirtaki').owlCarousel({
        loop: true,
        nav: false,
        items: 1,
        dots: false,
        autoplay:true
    });

    // Go to the next item
    $('.owlNextSirtaki').click(function () {
        $('.owl-sirtaki').trigger('next.owl.carousel');
    })

    // Go to the previous item
    $('.owlPrevSirtaki').click(function () {
        // With optional speed parameter
        // Parameters has to be in square bracket '[]'
        $('.owl-sirtaki').trigger('prev.owl.carousel', [300]);

    });

    // OWL-carousel Block

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-block').each(function () {

        var newID = 'owl-carousel-block' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: true,
            nav: false,
            items: 1,
            dots: true
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextBlock' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevBlock' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextBlock' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevBlock' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });


    // Pour sirtaki

    $('.openSirtakiCarousel').click(function(){
        index = $(this).index();
        $('.owl-carousel-singleSirtakiContainer').addClass('active');
        $('.owl-carousel-sirtaki').trigger('to.owl.carousel', [index, 500, true]);
    });

    $('.closeSirtakiCarousel').click(function(){
        $('.owl-carousel-singleSirtakiContainer').removeClass('active');
    });

    // OWL-carousel offers

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.offersSingle').owlCarousel({
        loop: false,
        freeDrag: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        items: 1,
    })

    $('.carouselOffers').each(function () {

        var newID = 'owl-carousel-offers' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: true,
            nav: false,
            items: 1,
            dots: true
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextOffers' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevOffers' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextOffers' + NEXTPREVnumber).click(function () {
            console.log(owl)
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevOffers' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });

    // OWL-carousel Partners

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-partners').each(function () {

        var newID = 'owl-carousel-partners' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;

        var owl = $('#' + newID);


        owl.owlCarousel({
            loop: false,
            nav: false,
            margin: 15,
            dots: false,
            responsiveClass:true,
                responsive:{
                    0:{
                        items:2,
                    },
                    767:{
                        items:3,
                    },
                    991:{
                        items:4,
                    }
                }
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextPartners' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevPartners' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextPartners' + NEXTPREVnumber).click(function () {
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevPartners' + NEXTPREVnumber).click(function () {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);

        });

        NEXTPREVnumber++;

    });

    function toggleLoop() {
        $('.owl-carousel-partners').each(function () {

            var itemsCount;
            var loopStatus = false;

            itemsCount = $(this).find('.owl-item:not(.cloned)').length;

            calcWindowWidthAndHeight();

            if (itemsCount > 4) {
                loopStatus = true;

            } else if (itemsCount == 4) {

                loopStatus = w < 991 ? true : false;

            } else if (itemsCount == 3) {

                loopStatus = w < 767 ? true : false;

            } else {

                loopStatus = w >= 767 ? false : false;

            }

            loopStatusCurrent = $(this).data('owl.carousel').options.loop;
            if (loopStatusCurrent != loopStatus) {
                if (loopStatus) {
                    $(this).data('owl.carousel').options.loop = true;
                    $(this).trigger('refresh.owl.carousel');
                    // L'OPTION LOOP EST TRUE
                    // Do something...
                    $(this).parent().find('.owlPrevPartners').addClass('show');
                    $(this).parent().find('.owlNextPartners').addClass('show');

                } else if (!loopStatus) {
                    $(this).data('owl.carousel').options.loop = false;
                    $(this).trigger('refresh.owl.carousel');
                    // L'OPTION LOOP EST FALSE
                    // Do something...
                    $(this).parent().find('.owlPrevPartners').removeClass('show');
                    $(this).parent().find('.owlNextPartners').removeClass('show');
                }
            }


        });
    }

    toggleLoop();
    $(window).resize(toggleLoop);



    // menu

    $('.toggleMenu').click(function(){
        $(this).toggleClass('active');
        $('.headerMobile').toggleClass('active');
        $('html').toggleClass('oh');
    });

    $('.toggleSearch').click(function(){
        $('.searchFormContainer').addClass('active');
    });

    $('.closeSearch').click(function(){
        $('.searchFormContainer').removeClass('active');
    });

    $('.mainMenu-item').click(function(){
        $('.mainMenu-item').removeClass('active');
        $(this).toggleClass('active');
    });

    $('.mainMenu-item').click(function(){
        calcWindowWidthAndHeight();

        if(w <= 1199){
            h = $(this).find('.mainMenu-subMenuContentInner').outerHeight(true) + 20;

            if($(this).find('.mainMenu-subMenuContent').hasClass('active')){
                $('.mainMenu-subMenuContent').removeClass('active');
                $('.mainMenu-subMenuContent').css('height', 0);
            } else {
                $('.mainMenu-subMenuContent').removeClass('active');
                $('.mainMenu-subMenuContent').css('height', 0);
                $(this).find('.mainMenu-subMenuContent').addClass('active');
                $(this).find('.mainMenu-subMenuContent').css('height', h);
            }
        }
    });

    function resetHeightOnSubMenu(){
        calcWindowWidthAndHeight();
        $('.mainMenu-item').removeClass('active');

        if(w > 1199){
            $('.mainMenu-subMenuContent').css('height', 64);
        } else {
            $('.mainMenu-subMenuContent').css('height', 0);
        }
    }

    resetHeightOnSubMenu();
    $(window).resize(resetHeightOnSubMenu);


    // Add arrow to each button
    $('.btn').each(function(){
        if($(this).is('input')){

        } else {
            $(this).append( '<svg version="1.1" class="arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 75.28 58.48" style="enable-background:new 0 0 75.28 58.48;" xml:space="preserve"><polygon points="46.08,0.43 41.13,5.38 61.43,25.69 0,25.69 0,32.69 61.43,32.69 41.13,52.99 46.08,57.94 74.83,29.19 "/></svg>');
        }
    });


    //--------------------------------//
    //-------- SMOOTH ANCHOR ---------//
    //--------------------------------//

    function juizScrollTo(element){
        $(element).click(function(){
            var goscroll = false;
            var the_hash = $(this).attr("href");
            var regex = new RegExp("\#(.*)","gi");
            var the_element = '';

            if(the_hash.match("\#(.+)")) {
                the_hash = the_hash.replace(regex,"$1");

                if($("#"+the_hash).length>0) {
                    the_element = "#" + the_hash;
                    goscroll = true;
                }
                else if($("a[name=" + the_hash + "]").length>0) {
                    the_element = "a[name=" + the_hash + "]";
                    goscroll = true;
                }

                if(goscroll) {
                    $('html, body').animate({
                        scrollTop:$(the_element).offset().top - 200
                    }, 'slow');
                    return false;
                }
            }
        });
    };
    juizScrollTo('a[href^="#"]');


    // HOMEPAGE VIDEO SWEET ALERT
    $('.itemPreview-popup').click(function(){
        var iframe = $(this).attr('data-src');

        Swal.fire({
            showConfirmButton: false,
            showCloseButton: true,
            width: '80%',
            html: '<iframe width="100%" height="300" src="' + iframe + '?wmode=transparent&amp;iv_load_policy=3&amp;autoplay=0" frameborder="0"></iframe>'
        });
    });

    $('.datepicker-tool').datepicker({
        autoClose: true,
        minDate: new Date()
    });

    $(document).on('change', '#place_val_form', function(e) {
        e.preventDefault();




        // loader(true);
        var typeUri = $(this).val();
        $( "#page_filters_container" ).load( typeUri, function(ajaxContent) {
            // loader(false);
            history.pushState(ajaxContent, null, typeUri);
            $('.selectSIT').select2({
                width: '100%'
            });
            $('.viewList').addClass('active');
            $('.viewList').click(function() {
                $('.viewMap').removeClass('active');
                $(this).addClass('active');
                $('.listingMap').hide();
                $('.listingList').show();
            });

            $('.viewMap').click(function() {
                $('.viewList').removeClass('active');
                $('#mapid').css('left', '0');
                $('#mapid').css('position', 'relative');
                $(this).addClass('active');
                $('.listingMap').show();
                $('.listingList').hide();
            });
            initMap();
            $('.datepicker-tool').datepicker({
                autoClose: true,
                minDate: new Date()
            });

        } );
    });

    $(document).on('change', '.filters-select', function(e) {
        e.preventDefault();

        // loader(true);
        var typeUri = $(this).val();
        $( "#page_filters_container" ).load( typeUri, function(ajaxContent) {
            // loader(false);
            history.pushState(ajaxContent, null, typeUri);
            $('.selectSIT').select2({
                width: '100%'
            });
            $('.viewList').addClass('active');
            $('.viewList').click(function() {
                $('.viewMap').removeClass('active');
                $(this).addClass('active');
                $('.listingMap').hide();
                $('.listingList').show();
            });

            $('.viewMap').click(function() {
                $('.viewList').removeClass('active');
                $('#mapid').css('left', '0');
                $('#mapid').css('position', 'relative');
                $(this).addClass('active');
                $('.listingMap').show();
                $('.listingList').hide();
            });
            initMap();
            $('.datepicker-tool').datepicker({
                autoClose: true,
                minDate: new Date()
            });

        } );
    });



    $(document).on('change', '.filters-checkboxes', function() {
        var isChecked = $(this).is(':checked');
        var cUrl = $(this).attr('data-url');



        // loader(true);
        $( "#page_filters_container" ).load( cUrl, function(ajaxContent) {
            // loader(false);
            history.pushState(ajaxContent, null, cUrl);
            $('.selectSIT').select2({
                width: '100%'
            });
            $('.viewList').addClass('active');
            $('.viewList').click(function() {
                $('.viewMap').removeClass('active');
                $(this).addClass('active');
                $('.listingMap').hide();
                $('.listingList').show();
            });

            $('.viewMap').click(function() {
                $('.viewList').removeClass('active');
                $('#mapid').css('left', '0');
                $('#mapid').css('position', 'relative');
                $(this).addClass('active');
                $('.listingMap').show();
                $('.listingList').hide();
            });
            initMap();
            $('.datepicker-tool').datepicker({
                autoClose: true,
                minDate: new Date()
            });

        } );
    });

    $('.travelJournal').click(function() {
        var sit = $(this);
        var id = sit.find('input[name="sit_id"]').val();
        var method = sit.find('input[name="action"]').val();

        $.ajax({
            'url': ajaxurl,
            'type': 'GET',
            'async': false,
            'data': '&id=' + id + '&method=' + method + '&action=add_sit',
            success: function (response) {
                var res = JSON.parse(response);
                if (method == 'add') {
                    sit.find('input[name="action"]').val('delete');
                    sit.find('p').html('Cette activité est disponible<span>dans votre carnet de voyage</span>');
                }
                if (method == 'delete') {
                    sit.find('input[name="action"]').val('add');
                    sit.find('p').html('Ajouter à mon<span>carnet de voyage</span>');
                }
            }
        });
    });

    $('.removeSit').click(function() {
        var sit = $(this);
        var id = sit.find('input[name="sit_id"]').val();

        $.ajax({
            'url': ajaxurl,
            'type': 'GET',
            'async': false,
            'data': '&id=' + id + '&method=delete&action=add_sit',
            success: function (response) {
                var res = JSON.parse(response);
                $('.sitContainer').each(function() {
                    if ($(this).attr('data-sit-id') == id) {
                        $(this).remove();
                    }
                });
            }
        });
    });



    //Accordeon presse

    var heightMax = 0;
    $('.foldersCard').each(function() {
        var height = $(this).outerHeight();

        if(height > heightMax) {
            heightMax = height;
        }
    });
    $('.foldersCard.show').css('height', heightMax);

    $('.btnHide').click(function() {
        $(this).parent().find('.hide').each(function() {
            $(this).css('height', heightMax);
        });
        $(this).css('display', 'none');
    });

    $('.btnHideComm').click(function() {
        var height = $(this).parent().find('.innerContainerCache').outerHeight(true);
        //console.log(height);

        $('.containerCache').css('height', height);
        $(this).css('display', 'none');
    });



    // SELECT

    $('.selectSIT').select2({
        width: '100%'
    });

    // INSTA HOME

    $('.jr-insta-thumb').find('ul.thumbnails').prepend('<li class="GraphImage FalseGraphImage"></li><li class="GraphImage FalseGraphImage"></li>');

    // BANDEAU EMERGENCY

    // Taille de l'ecran en largeur
    var wW = $(window).width();

    // Taille du texte en largeur
    var textW = $('.infoSticky').outerWidth(true);

    // Taille du bouton de fermeture
    var closeBtnW = $('.closeBtn').outerWidth(true);

    // Taille max du décalage du texte à droite
    var maxTrans = $(window).width() - closeBtnW;

    // Taille max du décalage du texte à gauche
    var minTrans = 0 - $('.infoSticky').outerWidth(true);

    // VARS
    var textDefilVar;
    var textDefilVarToggle;

    // Faire défiler le texte de 1px vers la gauche
    function textDefil(){
        var currentTrans = parseInt($('.infoSticky').css('transform').split(',')[4]);

        if(currentTrans > minTrans){
            currentTrans--;
            $('.infoSticky').css('transform', 'translateX('+currentTrans+'px)');

        } else { // Si le texte atteint le max décalage à gauche, revient tout à droite
            currentTrans = maxTrans;
            $('.infoSticky').css('transform', 'translateX('+currentTrans+'px)');
        }
    }

    // Activer le defilement ou pas
    function emergencyStickyPosition(){
      wW = $(window).width()
      maxTrans = $(window).width() - closeBtnW;

      // Activer le defilement si le texte depasse la largeur de l'ecran
      if(textW > (wW - closeBtnW)){
        $('.infoSticky').css('transform', 'translateX('+maxTrans+'px)');

        if(!textDefilVarToggle){
          textDefilVar = setInterval(textDefil, 10);
          textDefilVarToggle = true;
        }

      // desactiver le defilement si le texte depasse la largeur de l'ecran
      } else {
        clearInterval(textDefilVar);
        textDefilVarToggle = false;
        $('.infoSticky').css('transform', 'translateX(0px)');
      }
    }

    emergencyStickyPosition();
    $( window ).resize(emergencyStickyPosition);
});
